
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import accountService from "../../services/account-service";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

const moment = require("moment");

import { VueTreeList, Tree } from "vue-tree-list";

@Component({
  components: { Header, VueTreeList, Loader, PopUp },
})
export default class ChartsOfAccounts extends Vue {
  public filter = null;
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  public perpage = 100;
  search_code = "";
  public option_group: any = [];
  option_accounts: any = [];
  obj_params: any = {};
  selected_item_coa: any = {};
  params_parent_code: any = {};
  searchTerm = "";
  public import_file: any = null;
  loading = true;
  public import_response = "";
  public selected_code: any = [];
  public flattenedData: any = [];
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  newTree: any = {};
  data: any = null;

  public fields = [

    {
      key: "code",
    },
    {
      key: "title",
    },
    {
      key: "parent_title",
      label: "Parent",
    },
    {
      key: "group",
    },
    {
      key: "node_type",
      label: "Type",
    },
    {
      key: "created_at",
      label: "Date",
      formatter: (value: any) => {
        return moment(String(value)).format("MM/DD/YYYY");
      },
    },
  ];

  get rows() {
    return this.selected_items.length;
  }
  public selected_items = [
    {
      id: "",
      title: "",
      created_at: "",
    },
  ];

  onRowClicked(item: any, index: any, event: any) {
    this.selected_item_coa = item;
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-show");
  }

  retrieve(id: any) {
    this.loading = true;
    accountService
      .getAccountGroup()
      .then((response) => {
        let group = response.data;
        this.option_group = group.map((item: any) => {
          return { value: item.title, text: item.title };
        });
        // this.option_group.push({ value: 0, text: "Please select an option" });
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
    accountService
      .getAccountInHierarchy()
      .then((res) => {
        this.data = new Tree(res.data);
        this.loading = false;
        this.selected_code = res.data;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  }
  submit_import() {
    const isValidFile = this.validateFile(this.import_file);
    
    if(!isValidFile){
      this.showModal("Invalid file. Please upload a valid file by download the sample.",[],"error")
    }else {
    this.import_response = "Please wait, it will take time.";
    this.loading = true;
    this.import_response = "";
    if (this.import_file != null) {
      accountService
        .bulkImportChartOfAccount(this.import_file)
        .then((response) => {
          this.import_response = "Uploaded.";
          this.retrieve(1);
          this.loading = false;
        })
        .catch((e) => {
          this.import_response = "Error occured while uploading.";
          this.loading = false;
          console.log(e);
        });
    } else {
      this.import_response = "Please upload the file";
      this.loading = false;
    }
  }
  }

  onClick(params: any) {
    this.loading = true;
    accountService
      .getChildsOfAccount(params.id)
      .then((res) => {
        this.selected_items = res.data.result;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  }
  submit() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.obj_params, ['title', 'code', 'node_type', 'category', 'group'])
    const error_list_1 = utils.getEmptyKeys(this.params_parent_code, ['title'])
    const combinedErrorList = [...error_list, ...error_list_1];

    if (combinedErrorList.length > 0) {
      this.error = utils.generateErrorMessage(combinedErrorList)
    }
    else {
      //in submit we check that account title or account code is already exist or not
      const code = this.obj_params.code;
      const title = this.obj_params.title;
      if (code !== undefined && title !== undefined) {
        if (!this.obj_params.id) {
          accountService.checkAccountExist(title, code)
            .then((res) => {
              if (res.status === 200) {
                // Account does not exist, call the create() function
                this.create();
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              this.error = error.response.data.data;
            });
        }
        else {
          this.create()
        }
      }
    }
  }
  create() {
    this.obj_params["parent_code"] = this.params_parent_code.id;
    this.obj_params["parent_title"] = this.params_parent_code.title;
    if (this.obj_params.id) {
      this.loading = true;
      accountService
        .updateAccount(this.obj_params)
        .then((response) => {
          this.retrieve(1);
          this.obj_params = {};
          this.option_accounts = [];
          this.params_parent_code = {};
          this.showModal("Updated successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
          this.loading = false;
        });
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-show");
    } else {
      this.loading = true;
      accountService
        .createAccount(this.obj_params)
        .then((response) => {
          this.retrieve(1);
          this.obj_params = {};
          this.option_accounts = [];
          this.showModal("Created Successfully", [], "success");
          this.loading = false;
          this.onClick(this.params_parent_code);
          this.params_parent_code = {};
        })
        .catch((e) => {
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
          this.loading = false;
        });
    }
  }

  search_parent_account(title: any) {
    if (title.length > 2) {
      accountService
        .getByTitle(title)
        .then((response) => {
          this.option_accounts = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.option_accounts = [];
    }
  }

  get_parent_account(data: any) {
    this.params_parent_code = data;
    if (this.params_parent_code) {
      this.option_accounts = [];
    }
  }

  edit_account(data: any) {
    this.obj_params = data;

    if (this.obj_params.node_type == "master") {
      this.obj_params.node_type == "Master";
    } else if (this.obj_params.node_type == "node") {
      this.obj_params.node_type == "Node";
    }

    this.params_parent_code.id = data["parent_code_id"];
    this.params_parent_code.title = data["parent_title"];

    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create");
  }

  search_code_method(code: any) {
    accountService
      .getByTitleMaster(code)
      .then((response) => {
        this.selected_items = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  reset_search() {
    this.retrieve(1);
    this.search_code = "";
  }

  mounted() {
    this.retrieve(1);
  }

  reset() {
    this.obj_params = {};
    this.option_accounts = [];
    this.params_parent_code = {};
    this.error = "";
  }
  validateFile(file:any) {
      // Check if a file is selected
      if (!file) {
        return null;
      }
      // Get the file name
      const fileName = file.name;

      // Define allowed file extensions (PDF and Excel)
      const allowedExtensions = ['.xls', '.xlsx','.ods','.xlsm,','.xlsb','.xltx','.xltm','.xlam'];
      // Check if the file has an allowed extension
      const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
      return isValid ? true : false;
    }
}
